import { render, staticRenderFns } from "./BindWX.vue?vue&type=template&id=7222eedc&scoped=true&"
import script from "./BindWX.vue?vue&type=script&lang=js&"
export * from "./BindWX.vue?vue&type=script&lang=js&"
import style0 from "./BindWX.vue?vue&type=style&index=0&id=7222eedc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7222eedc",
  null
  
)

export default component.exports