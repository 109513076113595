<template>
  <div class="container">
    <van-nav-bar title="绑定微信" left-arrow @click-left="goBack()" />

    <div class="zmdescp">
      绑定微信后，可在微信中自动登录， 最多绑定3个微信账号
    </div>

    <div v-if="HasBandList.length > 0">
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
        >已绑定</van-divider
      >
      <van-cell-group>
        <van-cell
      
          v-for="item in HasBandList"
          :key="item.openid"
        >
          <van-col span="8">
            <van-image
              slot="default"
              width="1.5rem"
              height="1.5rem"
              fit="cover"
              round
              :src="item.headimgurl"
            />
          </van-col>
          <van-col span="10" align="bottom">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.nickname }}</h3>
            </div>
          </van-col>
          <van-col span="6">
            <van-tag
          round
          size="large"
          color="#9d1d22"
          @click="Unbind(item)"
          >解绑</van-tag
        >
          </van-col>
        </van-cell>
      </van-cell-group>
    </div>

    <div v-if="NotBandList.length > 0">
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
        >待绑定</van-divider
      >
      <van-cell-group>
        <van-cell
        
          v-for="item in NotBandList"
          :key="item.openid"
        >
          <van-col span="8">
            <van-image
              slot="default"
              width="1.5rem"
              height="1.5rem"
              fit="cover"
              round
              :src="item.headimgurl"
            />
          </van-col>
          <van-col span="16" align="bottom">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.nickname }}</h3>
            </div>
          </van-col>
        </van-cell>
      </van-cell-group>

      <div class="btn-box">
        <van-button type="info" size="large" round block @click="BindWXUser"
          >确认绑定</van-button
        >
      </div>
    </div>
    <div>
      <van-empty
        class="custom-image"
        :image="wxznpz"
        description="请关注中酿IT服务公众号"
      >
        <!-- <van-button
          round
          type="default"
          size="large"
          class="bottom-button"
          block
          @click="ChangePassWordHandle"
          >已关注</van-button
        > -->
      </van-empty>
    </div>
  </div>
</template>

<script>
//import { changePassWord } from "@/api/user";
import { GetAppId, GetWXUserInfo, BingWXUser,  UnbindingWXUser} from "@/api/wxuser";

import { Toast } from "vant";

import wxznpz from "@/assets/wxznpz.png";

export default {
  name: "user-BindWX",
  data() {
    return {
      appid:"",
      wxznpz: wxznpz,
      code: "",
      wxuser: [],
    };
  },
  created() {
    this.initData();
    this.getWXUserInfo();
  },
  computed: {
    HasBandList() {
      return this.wxuser.filter((item) => {
        if (item.isbinding && item.nickname!=undefined && item.nickname!="") {
          return item;
        }
      });
    },
    NotBandList() {
      return this.wxuser.filter((item) => {
        if (!item.isbinding && item.subscribe == 1) {
          return item;
        }
      });
    },
    NotSubscribeList() {
      return this.wxuser.filter((item) => {
        if ( item.nickname==undefined || item.nickname=="") {
          return item;
        }
      });
    },
  },
  methods: {
    goBack(){
      if(this.$route.query.back){
        this.$router.back();
      }
      else{
        this.$router.replace({ path: '/user'})
      }
    },
    initData() {
      var queryString = location.search; 
      // Further parsing:
      let params = new URLSearchParams(queryString);
      this.code = params.get("code"); // this.$route.query.code;location.search
    },

    async getAppID() {
      const data = await GetAppId(this.user);
      if (data.data.success) {
        this.appid = data.data.data;
      } else {
        Toast(data.data.errorMessage);
      }
    },
    async getWXUserInfo() {
      
      try {
        const data = await GetWXUserInfo(this.code);
        if (data.data.success) {
          this.wxuser = data.data.data;
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        Toast("获取微信绑定信息异常:" + error);
      }
    },

    async Unbind(item){
      try {
        const data = await UnbindingWXUser(item.id);
        if (data.data.success) {
          for (let index = 0; index < this.wxuser.length; index++) {
            const element = this.wxuser[index];
            if(element.id==item.id)
            {
              this.wxuser.splice(index, 1);
              break;
            }
          }
          Toast("解绑成功");
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        Toast("微信解绑信息异常:" + error);
      }
    },
    

    //
    async BindWXUser() {
      try {
        const wxuser = this.NotBandList[0];
        const data = await BingWXUser({ ...wxuser });
        if (data.data.success) {
          this.appid = data.data.data;
          Toast("绑定成功");
          this.$router.push("/user");
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
        Toast("保存失败：" + error);
      }
    },
    
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
.zmdescp {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.zntitle {
  height: 1.5rem;
}
.bottom-button {
  width: 160px;
  height: 40px;
}
</style>
